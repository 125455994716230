import groq from 'groq'

import { blogPostWithoutBodyFragment, blogPostOptionsFragment } from './blog'
import { contentFragment } from './content'
import { imageFragment } from './image'
import { getProductFragment } from './product'
import { newsletterFragment } from './site'
import { videoFragment } from './video'
import { Preview } from '@lib/sanity/client'
import { buttonStyleFragment } from './link'

const freeformBlockFragment = groq`
  textAlign,
  maxWidth,
  content[] {
    ${contentFragment}
  }
`

const accordionsBlockFragment = groq`
  items[] {
    title,
    "id": _key,
    content[] {
      ${contentFragment}
    }
  }
`

const getProductCardBlockFragment = (preview: Preview) => groq`
  product->{
    ${getProductFragment(preview)}
  }
`

const authStringsFragment = groq`
  signupFirstName,
  signupFirstNamePlaceholder,
  signupFirstNameMissing,
  signupLastName,
  signupLastNamePlaceholder,
  signupLastNameMissing,
  signupEmail,
  signupEmailPlaceholder,
  signupEmailMissing,
  signupPassword,
  signupPasswordPlaceholder,
  signupPasswordMissing,
  signupErrorMessage[] {
    ${contentFragment}
  },
  signupSubmit,
  loginErrorMessage[] {
    ${contentFragment}
  },
  loginFailedMessage,
  loginSubmit,
  passwordRecoveryErrorMessage[] {
    ${contentFragment}
  },
  passwordRecoverySuccessMessage[] {
    ${contentFragment}
  },
  passwordRecoverySubmit,
`

const signupFormBlockFragment = groq`
  "authStrings": *[_type == "strings" && locale == $locale][0] {
    ${authStringsFragment}
  },
  active,
`

const loginFormBlockFragment = groq`
  "authStrings": *[_type == "strings" && locale == $locale][0] {
    ${authStringsFragment}
  },
  active,
`

const passwordRecoveryFormBlockFragment = groq`
  "authStrings": *[_type == "strings" && locale == $locale][0] {
    ${authStringsFragment}
  },
  active,
`

const accountStringsFragment = groq`
  account,
  accountAccountDetailsHeading,
  accountViewAddresses,
  accountViewSubscriptions,
  accountDefaultAddressLabel,
  accountAddAddressHeading,
  accountEditAddressHeading,
  accountFirstName,
  accountFirstNamePlaceholder,
  accountFirstNameMissing,
  accountLastName,
  accountLastNamePlaceholder,
  accountLastNameMissing,
  accountCompany,
  accountCompanyPlaceholder,
  accountCompanyMissing,
  accountAddressLine1,
  accountAddressLine1Placeholder,
  accountAddressLine1Missing,
  accountAddressLine2,
  accountAddressLine2Placeholder,
  accountCity,
  accountCityPlaceholder,
  accountCityMissing,
  accountCountry,
  accountCountryPlaceholder,
  accountCountryMissing,
  accountZip,
  accountZipPlaceholder,
  accountZipMissing,
  accountPhone,
  accountPhonePlaceholder,
  accountPhoneMissing,
  accountSetAsDefault,
  addressErrorMessage,
  accountAddAddress,
  accountEditAddress,
  accountProductListHeading,
  accountProductListProduct,
  accountProductListViewProduct,
  accountOrderListHeading,
  accountOrderListOrder,
  accountOrderListDate,
  accountOrderListPaymentStatus,
  accountOrderListFulfillmentStatus,
  accountOrderListTotal,
  accountOrderDetailsHeading,
  accountOrderDetailsDate,
  accountOrderDetailsProduct,
  accountOrderDetailsSku,
  accountOrderDetailsPrice,
  accountOrderDetailsQuantity,
  accountOrderDetailsTotal,
  accountOrderDetailsSubtotal,
  accountOrderDetailsShipping,
  accountOrderDetailsTax,
  accountOrderDetailsBillingAddress,
  accountOrderDetailsShippingAddress,
  accountOrderListPaymentStatusAuthorized,
  accountOrderListPaymentStatusPaid,
  accountOrderListPaymentStatusPartiallyPaid,
  accountOrderListPaymentStatusPartiallyRefunded,
  accountOrderListPaymentStatusPending,
  accountOrderListPaymentStatusRefunded,
  accountOrderListPaymentStatusVoided,
  accountOrderListFulfillmentStatusFulfilled,
  accountOrderListFulfillmentStatusInProgress,
  accountOrderListFulfillmentStatusOnHold,
  accountOrderListFulfillmentStatusOpen,
  accountOrderListFulfillmentStatusPartiallyFulfilled,
  accountOrderListFulfillmentStatusPendingFulfillment,
  accountOrderListFulfillmentStatusRestocked,
  accountOrderListFulfillmentStatusScheduled,
  accountOrderListFulfillmentStatusUnfulfilled,
  accountProductListEmpty,
  accountOrderListEmpty,
`

const accountDetailsBlockFragment = groq`
  "accountStrings": *[_type == "strings" && locale == $locale][0] {
    ${accountStringsFragment}
  },
  active
`

const accountAddressDetailsBlockFragment = groq`
  "accountStrings": *[_type == "strings" && locale == $locale][0] {
    ${accountStringsFragment}
  },
  active
`

const accountProductListBlockFragment = groq`
  "accountStrings": *[_type == "strings" && locale == $locale][0] {
    ${accountStringsFragment}
  },
  active
`

const accountOrderListBlockFragment = groq`
  "accountStrings": *[_type == "strings" && locale == $locale][0] {
    ${accountStringsFragment}
  },
  active
`

const getCollectionCarouselBlockFragment = (preview: Preview) => groq`
  collection->{
    products[]->{
      ${getProductFragment(preview)}
    }
  }
`

const productBundleFormBlockFragment = (preview: Preview) => groq`
  productBundle->{
    name,
    slots[] {
      name,
      "selectedOptionNames": products[] {
        "productId": selection.id,
        "list": selection.options[selected == true].name
      },
      "products": *[
        _type == "product" &&
        _id in ^.products[].selection.id &&
        locale == $locale &&
        !wasDeleted
      ] {
        ${getProductFragment(preview)}
      },
      showSelection,
      label
    },
    variantMap[] {
      products[] {
        "id": selection.id,
        "variantIds": selection.variants[selected == true].id
      }
    },
    gallery[] {
      variantCombination,
      photos[] {
        ${imageFragment}
      }
    },
  },
  showGallery,
`

const blogPostCardBlockFragment = groq`
  post->{
    ${blogPostWithoutBodyFragment}
  },
  options {
    ${blogPostOptionsFragment}
  }
`

const getContentCarouselBlockCarouselFragment = (preview: Preview) => groq`
  items[] {
    _key,
    _type,
    _type == 'freeform' => {
      "freeform": {
        ${freeformBlockFragment}
      }
    },
    _type == 'photo' => {
      "photo": {
        ${imageFragment}
      }
    },
    _type == 'product' => {
      "product": *[
        _type == "product" &&
        _id == ^._ref
      ][0] {
        ${getProductFragment(preview)}
      }
    },
    _type == 'blogPost' => {
      "blogPost": *[
        _type == "blogPost" &&
        _id == ^._ref
      ][0] {
        ${blogPostWithoutBodyFragment}
      }
    },
    _type == 'video' => {
      "video": {
        ${videoFragment}
      }
    },
  },
`

const calendlyWidgetFragment = groq`
  url,
  hideEventDetails,
  hideCookieSettings,
`

const cookieDeclarationFragment = groq`
  "cookieBotId": *[_type == "generalSettings" && locale == $locale][0].cookieBotId,
  active,
`

export const demoFormFragment = groq`
  service,
  hubSpotFormId,
  hubSpotNewsletterFormId,
  klaviyoListID,
  klaviyoNewsletterListID,
  hidePhoneInput,
  submit,
  strings,
  successMsg[] {
    ${contentFragment}
  },
  errorMsg[] {
    ${contentFragment}
  },
  terms[] {
    ${contentFragment}
  },
  newsletterStatement[] {
    ${contentFragment}
  },
  buttonStyle {
    ${buttonStyleFragment},
    fontCase
  }
`

const iframeBlockFragment = groq`
  title,
  url,
  width,
  height,
`

const teamMemberCardFragment = groq`
  teamMember->{
    name,
    "slug": slug.current,
    jobTitle,
    about,
    linkedInUrl,
    blogAuthor->{
      "slug": slug.current,
    },
    photo {
      ${imageFragment}
    }
  }
`

const htmlBlockFragment = groq`
  code
`

export const getBlocksFragment = (preview: Preview) => groq`
  _key,
  _type,
  _type == 'freeform' => {
    ${freeformBlockFragment}
  },
  _type == 'accordions' => {
    ${accordionsBlockFragment}
  },
  _type == 'productCard' => {
    ${getProductCardBlockFragment(preview)}
  },
  _type == 'signupForm' => {
    ${signupFormBlockFragment}
  },
  _type == 'loginForm' => {
    ${loginFormBlockFragment}
  },
  _type == 'passwordRecoveryForm' => {
    ${passwordRecoveryFormBlockFragment}
  },
  _type == 'accountDetails' => {
    ${accountDetailsBlockFragment}
  },
  _type == 'accountAddressDetails' => {
    ${accountAddressDetailsBlockFragment}
  },
  _type == 'accountProductList' => {
    ${accountProductListBlockFragment}
  },
  _type == 'accountOrderList' => {
    ${accountOrderListBlockFragment}
  },
  _type == 'video' => {
    ${videoFragment}
  },
  _type == 'collectionCarousel' => {
    ${getCollectionCarouselBlockFragment(preview)}
  },
  _type == 'newsletter' => {
    ${newsletterFragment}
  },
  _type == 'productBundleForm' => {
    ${productBundleFormBlockFragment(preview)}
  },
  _type == 'blogPostCard' => {
    ${blogPostCardBlockFragment}
  },
  _type == 'contentCarousel' => {
    ${getContentCarouselBlockCarouselFragment(preview)}
  },
  _type == 'calendlyWidget' => {
    ${calendlyWidgetFragment}
  },
  _type == 'cookieDeclaration' => {
    ${cookieDeclarationFragment}
  },
  _type == 'demoForm' => {
    ${demoFormFragment}
  },
  _type == 'iframe' => {
    ${iframeBlockFragment}
  },
  _type == 'teamMemberCard' => {
    ${teamMemberCardFragment}
  },
  _type == 'html' => {
    ${htmlBlockFragment}
  }
 `
